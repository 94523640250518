exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-simuladores-simulador-economia-js": () => import("./../../../src/pages/simuladores/simulador-economia.js" /* webpackChunkName: "component---src-pages-simuladores-simulador-economia-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-pages-universidade-iberica-js": () => import("./../../../src/pages/universidade-iberica.js" /* webpackChunkName: "component---src-pages-universidade-iberica-js" */),
  "component---src-pages-videos-e-podcasts-js": () => import("./../../../src/pages/videos-e-podcasts.js" /* webpackChunkName: "component---src-pages-videos-e-podcasts-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-noticia-js": () => import("./../../../src/templates/noticia.js" /* webpackChunkName: "component---src-templates-noticia-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-produto-js": () => import("./../../../src/templates/produto.js" /* webpackChunkName: "component---src-templates-produto-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

